import React, { useState } from 'react';


function UserPrompt ({ user, setUser }) {

  const handleUserSubmit = () => {
    setUser(user);
    console.log('user:', user);
    //http request to lmabda function
    //lambda checks if user exists in db & either fetches data or creates new user
  };

  return (
    <div className='fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50'>
      <div className='bg-white p-4 rounded-lg shadow-lg'>
        <input
          type='text'
          value={user}
          onChange={(e) => setUser(e.target.value)}
          placeholder='Enter username'
          className='text-black p-2 border border-gray-300 rounded mb-2'
        />
        <button
          onClick={handleUserSubmit}
          className='bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600'
        >
          OK
        </button>
      </div>
    </div>
  )
}

function CreateExercise () {
  //button to create new exercise and add to db
  //updates grid

  return (
    <div>Create Excersise Component</div>
  )
}

function Grid () {

  return (
    <div>Grid</div>
  );
}

export default function Gym() {
  const [user, setUser] = useState('');

    return (
      <div className="min-h-screen bg-black text-white items-center justify-center flex flex-col">
        <h1>Gym</h1>
        <p>Gym tracker app</p>
      <div>
        <UserPrompt user={user} setUser={setUser}/>
      </div>
        
      </div>
      
    );
}
