import { useState } from 'react';
import { useRef } from 'react';

let currentPlayingAudio = false;
let pendingPlayAudio = null;

function Box({eighthIndex, sound}) {

  const audioRef = useRef(null); //creates a ref object with initial value null
  const [isHovered, setIsHovered] = useState(false);
  const shouldPauseRef = useRef(false);

  const playEighth = () => {
    //prevents audio playing if a sound is active
    if (currentPlayingAudio) {
      console.log("currentAudioPlaying!!")
      pendingPlayAudio = {playEighth};
      return;
    }

    console.log("not stuck")
    currentPlayingAudio = true;
    pendingPlayAudio = null;

    //calculate start time and play in loop mode
    const eighthDuration = audioRef.current.duration / 8;
    console.log("equ", eighthDuration)
    const eighthEndTime = (eighthIndex + 1) * eighthDuration;

    audioRef.current.currentTime = eighthIndex * eighthDuration; //calculates start time based on index
    console.log(audioRef);
    audioRef.current.play();
    audioRef.current.loop = true;

    audioRef.current.onended = () => {
        if (shouldPauseRef.current){pauseAudio();return;}
        audioRef.current.play();
        audioRef.current.currentTime = eighthIndex * eighthDuration;
      }
      //ensure loop only plays specific segment
      audioRef.current.ontimeupdate = () => { //ontimeupdate event fires continously as audio plays
        if (audioRef.current.ended){return;}
        
         // Check if should pause at the end of the segment
        if (audioRef.current.currentTime >= eighthEndTime && shouldPauseRef.current) {
        pauseAudio()
        };
      
      
        if (audioRef.current.currentTime >= eighthEndTime) {
          audioRef.current.currentTime = eighthIndex * eighthDuration;
          };

        };
      };
  
  const pauseAudio = () => {
    console.log("trying to pause")
    audioRef.current.pause();
    currentPlayingAudio = false;
    shouldPauseRef.current = (false);
    if (pendingPlayAudio){
      pendingPlayAudio.playEighth();
    }
  }
  const handleMouseEnter = () => {
    shouldPauseRef.current = false;
    setIsHovered(true);
    playEighth();
  };
  
  const handleMouseLeave =() => {
    setIsHovered(false);
    shouldPauseRef.current = true;
  };

  const handleTouchStart =() => {
    handleMouseEnter();
  };
  
const handleTouchEnd =() => {
  handleMouseLeave();
};

  return (
  <div className="relative w-20 h-20 bg-transparent" /*hitbox with padding to ensure hitboxes touch*/
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    onTouchStart={handleTouchStart}
    onTouchEnd={handleTouchEnd}
  >
    <div className="absolute inset-0 flex items-center justify-center">
      <div className={`border-4 border-white rounded-2xl bg-transparent p-4 w-16 h-16 ${isHovered ? 'box-hovered' : 'box'}`}>
        <audio ref={audioRef} src={sound}/> 
      </div>
    </div>
  </div>
  );
}

function Column({sound}) {
  //creates an array of indices for 8 segments
  const eighths = [0, 1, 2, 3, 4, 5, 6, 7];

  return (
    <div className="relative flex flex-col space-y-0">

      {eighths.map((num) => (
        <Box key={num} eighthIndex={num} sound={sound} />
      ))}
      
    </div>
  );
}

function Table() {
  const columns = [
    { sound: '/sounds/amen break 2bar 170bpm.wav' },
    { sound: '/sounds/think about it 2bar 170bpm.wav' },
    { sound: '/sounds/hot pants break 2bar 170bpm.wav' },
    { sound: '/sounds/good old music 2bar 170bpm.wav' }
  ];


  return(
  <div className="Table">
    <div className="Row flex space-x-0">
      {columns.map((column, index) => (
        <Column key={index} sound={column.sound} />
      ))}
    </div>
  </div>
  );
}

export default function App() {
  const [isReady, setIsReady] = useState(false);
  import('./Breaks.css')

  return (
    <div className="mt-7 min-h-screen bg-black text-white items-center justify-center flex flex-col">
      <Table />
    
      
    </div>
    
  );
}