import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
 
  return (
    <div className='min-h-screen bg-black/90 flex'>
        <title>HomePage</title>
        <aside className='mt-4 mb-4 p-3 rounded-xl bg-black/30'> 
          <h1 className='text-3xl mb-4 text-white font-semibold text-center mt-4'>Apps</h1>
          <div className='flex flex-col gap-4 w-full'>
            <a className="flex items-center gap-2 p-4 bg-gray-800 hover:bg-gray-700 rounded-2xl transition-all duration-300" href="/breaks">
              {/* <img src="/favicon.ico" className='w-6 h-6 opacity-50'/> */}
              <div className='grid grid-cols-2 gap-[2px] p-[1px] grid-row-2 w-6 h-6'>
                <div className='w-full h-full rounded-[2px] border-[1px] border-gray-500'></div>
                <div className='w-full h-full rounded-[2px] border-[1px] border-gray-500'></div>
                <div className='w-full h-full rounded-[2px] border-[1px] border-gray-500'></div>
                <div className='w-full h-full rounded-[2px] border-[1px] border-gray-500'></div>
              </div>
              <span className='text-white'>Breakbox</span> 
            </a>
            <a className="flex items-center gap-2 p-4 bg-gray-800 hover:bg-gray-700 rounded-2xl transition-all duration-300" href="/gym">
              <img src="/dumbell.png" className='w-6 h-6' />
              <span className='text-white'>Gym Tracker</span>
            </a>
          </div>
          </aside>
       <main className="flex-grow p-8 flex items-center justify-center">
        <div className="cube-container">
          <div className="cube">
            <div className="edge edge1"></div>
            <div className="edge edge2"></div>
            <div className="edge edge3"></div>
            <div className="edge edge4"></div>
            <div className="edge edge5"></div>
            <div className="edge edge6"></div>
            <div className="edge edge7"></div>
            <div className="edge edge8"></div>
            <div className="edge edge9"></div>
            <div className="edge edge10"></div>
            <div className="edge edge11"></div>
            <div className="edge edge12"></div>
          </div>
        </div>
      </main>
    </div>
    );
};

export default HomePage;
